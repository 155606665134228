export default [
  {
    header: 'Pages',
    icon: 'FileIcon',
    children: [
      {
        title: 'Orders',
        route: 'orders',
        icon: 'HomeIcon',
      },
      // {
      //   title: 'Second Page',
      //   route: 'second-page',
      //   icon: 'FileIcon',
      // },
    ],
  },
]
